<template>
	<div class="QRCode">
		<!-- <div style="display:flex; align-items: center; margin-bottom: 10px;">
      <span style="width:40px;">大小</span>
      <el-input-number style="width:150px;" v-model="qrSize" :step="2"></el-input-number>
    </div>
    <div style="display:flex; align-items: center; margin-bottom: 10px;">
      <span style="width:40px;">顶部</span>
      <el-input-number style="width:150px;" v-model="qrMtTop" :step="2"></el-input-number>
    </div> -->
		<div class="print_container">
			<div id="printMe1">
				<div
					v-for="item in selectData_p"
					:key="item.id"
					style="
						page-break-after: always;
						padding-top: 6px;
						padding-left: 4px;
					"
				>
					<div class="printItem">
						<!-- <qriously :size="qrSize" class="qrCanvas" :level="'L'" :padding="0"
							:value="`${$envConfig.OfficialWebsite}bx?deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}&test=aaaaaaaaaaaaaaaaaaaaaaaa`" /> -->

						<table class="table">
							<tr>
								<th
									colspan="3"
									style="
										font-size: 14px;
										height: 28px;
										line-height: 28px;
										border: #ffffff !important;
										border-bottom: #000000 !important;
									"
								>
									{{ valueComNew }}
								</th>
							</tr>
							<tr>
								<td colspan="3" style="font-size: 12px">
									{{ item.devInfoName }}
								</td>
							</tr>
							<tr>
								<!-- white-space: nowrap; 不换行 -->
								<!-- word-break: break-all; 换行 -->
								<td
									style="
										width: 40px;
										word-break: break-all; ;
									"
									colspan="1"
								>
									<span style="word-break: break-all; ">
										{{ item.devInfoDepartmentName }}
									</span>
								</td>
								<td colspan="2" style="font-size: 10px">
									<span style="word-break: break-all; ">
										{{ item.devInfoTypeName }}
									</span>
								</td>
							</tr>
							<tr>
								<td>规格</td>
								<!-- white-space: nowrap; 不换行 -->
								<!-- word-break: break-all; ; 换行 -->
								<td
									style="
										width: 70px;
										word-break: break-all; ;
									"
								>
									<span style="word-break: break-all; ">
										{{ item.devInfoNns1 }}
									</span>
								</td>
								<td rowspan="4">
									<qriously
										:size="qrSize"
										class="qrCanvas"
										:level="'L'"
										:padding="10"
										:value="item.valueUrl"
										style="
											margin-top: 0px;
											margin-left: 0px;
										"
									/>
								</td>
							</tr>
							<tr>
								<td>日期</td>
								<td>
									<span style="word-break: break-all; ">
										{{
											item.devInfoEnableTime
												| parseTime('{y}-{m}-{d}')
										}}
									</span>
								</td>
							</tr>
							<tr>
								<td>院内编码</td>
								<td style="font-size: 10px">
									<span style="word-break: break-all; ">
										{{ item.hospitalAssetCode }}
									</span>
								</td>
							</tr>
							<tr>
								<td>序列号</td>
								<td>
									<span style="word-break: break-all; ">
										{{ item.devInfoSerialNo }}
									</span>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="dialog-footer">
				<el-button @click="printCancel">取 消</el-button>
				<span v-print="'#printMe1'">
					<el-button type="primary" class="pringQR">确 定</el-button>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['selectData_p', 'valueComNew'],
	data() {
		return {
			qrSize: 100,
			qrMtTop: 0
		};
	},
	mounted() {},
	watch: {
		selectData_p: {
			handler(newVal, oldVal) {
				if (newVal.length > 0) {
					newVal.forEach(item => {
						let tempStr =
							'&test=aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

						let valueUrl = `${this.$envConfig.OfficialWebsite}bx?deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}`;
						tempStr = tempStr.substring(0, 80 - valueUrl.length);
						valueUrl = `${valueUrl}${tempStr}`;
						// {{ $envConfig.OfficialWebsite + 'bx?deviceId=' + item.devInfoId + '&hospitalId=' + item.affiliatedHospitalId }}
						item.valueUrl = valueUrl;
					});
				}
			},
			immediate: true
		}
	},
	methods: {
		printCancel() {
			this.$emit('update:isShowPrintDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
// 二维码
.QRCode {
	position: relative;

	.print_container {
		max-height: 500px;
		overflow-y: auto;
		background: #eeeeee;
		display: flex;
		padding: 10px;
		align-items: center;
		flex-direction: column;
	}

	#printMe1 {
		width: 100%;
		// height: 50mm;
		padding: 2mm;
		background: #ffffff;
	}

	.footer {
		width: 100%;
		text-align: right;
		margin-top: 10px;
		z-index: 10;

		span {
			padding-left: 10px;
		}
	}
}
.qrCanvas {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.qrCanvas canvas {
	// transform: scale(1.3, 1.3);
	// transform-origin: center center;
}
.printItem {
	width: 67mm;
	display: flex;
	// flex-direction: column;
	justify-content: center;
}

.printItem p {
	white-space: nowrap;
	border-right: 1px solid #000000;
	border-bottom: 1px solid #000000;
}

.printItem p .value {
	flex: 1;
	white-space: pre-wrap;
}

/* 表格初始化 bootstrap */
table {
	border-collapse: collapse;
	width: 100%;
	/* border: 1px solid #333333; */
}

.table {
	border-collapse: collapse !important;
	// border-left: 1px solid #333333;
	// border-top: 1px solid #333333;
	// border: 1px solid #dee2e6;
}
.table td,
.table th {
	background-color: #fff !important;
	height: 30px;
	line-height: 30px;
	height: 22px;
	line-height: initial;
	padding: 0 6px;
	border: 1px solid #000000;
	font-size: 12px;
	font-size: 10px;
}
.table-bordered th,
.table-bordered td {
	border: 1px solid #000000 !important;
}
.table-dark {
	color: inherit;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #000000;
}
.table .thead-dark th {
	color: inherit;
	border-color: #000000;
}
</style>
