import { hpostRequest } from '@u/htools.axios.js';
import { htimeFormat } from '@u/htools.web';
/*
 *@Description:公司管理设备、报修统计 ----->>>>>    公司管理设备统计
 *@ClassAuthor: ZXH
 *@Date: 2024-11-25
 */
export const getCompreportDevService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const priceFields1 = [
				'beginBuyPrice', 'endBuyPrice'
			];
			
			priceFields1.forEach(field => {
				if (dataJson.search[field] === 0) {
					dataJson.search[field] = null;
				}
			});

			if (dataJson.search.date) {
				const [beginBuyDate, endBuyDate] = dataJson.search.date;
				dataJson.search.beginBuyDate = htimeFormat(beginBuyDate);
				dataJson.search.endBuyDate = htimeFormat(endBuyDate);
			}
			const res = await hpostRequest('hcz/compreport/dev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 根据ID查弹出数据
 *@ClassAuthor: ZXH
 *@Date: 2024-11-25
 */
export const getCompreportDevlistService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const priceFields1 = [
				'beginBuyPrice', 'endBuyPrice'
			];
			
			priceFields1.forEach(field => {
				if (dataJson.search[field] === 0) {
					dataJson.search[field] = null;
				}
			});
			if (dataJson.search.date) {
				const [beginBuyDate, endBuyDate] = dataJson.search.date;
				dataJson.search.beginBuyDate = htimeFormat(beginBuyDate);
				dataJson.search.endBuyDate = htimeFormat(endBuyDate);
			}
			const res = await hpostRequest('hcz/compreport/devlist', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description:公司管理报修统计
 *@ClassAuthor: ZXH
 *@Date: 2024-11-25
 */
export const getCompreportRepairService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			
			const priceFields1 = [
				'beginBuyPrice', 'endBuyPrice'
			];
			
			priceFields1.forEach(field => {
				if (dataJson.dsearch[field] === 0) {
					dataJson.dsearch[field] = null;
				}
			});

			if (dataJson.dsearch.date) {
				const [beginBuyDate, endBuyDate] = dataJson.dsearch.date;
				dataJson.dsearch.beginBuyDate = htimeFormat(beginBuyDate);
				dataJson.dsearch.endBuyDate = htimeFormat(endBuyDate);
			}
			if (dataJson.rsearch.dateRepairTimeArr) {
				const [beginRepairTime, endRepairTime] = dataJson.rsearch.dateRepairTimeArr;
				dataJson.rsearch.beginRepairTime = htimeFormat(beginRepairTime);
				dataJson.rsearch.endRepairTime = htimeFormat(endRepairTime);
			}
			if (dataJson.rsearch.dateCompleteTimeArr) {
				const [beginCompleteTime, endCompleteTime] = dataJson.rsearch.dateCompleteTimeArr;
				dataJson.rsearch.beginCompleteTime = htimeFormat(beginCompleteTime);
				dataJson.rsearch.endCompleteTime = htimeFormat(endCompleteTime);
			}
			if (dataJson.rsearch.dateSignTimeArr) {
				const [beginSignTime, endSignTime] = dataJson.rsearch.dateSignTimeArr;
				dataJson.rsearch.beginSignTime = htimeFormat(beginSignTime);
				dataJson.rsearch.endSignTime = htimeFormat(endSignTime);
			}
			const priceFields2 = [
				'beginPrice', 'endPrice',
				'beginRealPrice', 'endRealPrice',
				'beginCost', 'endCost'
			];
			
			priceFields2.forEach(field => {
				if (dataJson.rsearch[field] === 0) {
					dataJson.rsearch[field] = null;
				}
			});
			const res = await hpostRequest('hcz/compreport/repair', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 根据ID查弹出数据 报修统计
 *@ClassAuthor: ZXH
 *@Date: 2024-11-25
 */
export const getCompreportRepairlistService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const priceFields1 = [
				'beginBuyPrice', 'endBuyPrice'
			];
			
			priceFields1.forEach(field => {
				if (dataJson.dsearch[field] === 0) {
					dataJson.dsearch[field] = null;
				}
			});

			if (dataJson.dsearch.date) {
				const [beginBuyDate, endBuyDate] = dataJson.dsearch.date;
				dataJson.dsearch.beginBuyDate = htimeFormat(beginBuyDate);
				dataJson.dsearch.endBuyDate = htimeFormat(endBuyDate);
			}
			if (dataJson.rsearch.dateRepairTimeArr) {
				const [beginRepairTime, endRepairTime] = dataJson.rsearch.dateRepairTimeArr;
				dataJson.rsearch.beginRepairTime = htimeFormat(beginRepairTime);
				dataJson.rsearch.endRepairTime = htimeFormat(endRepairTime);
			}
			if (dataJson.rsearch.dateCompleteTimeArr) {
				const [beginCompleteTime, endCompleteTime] = dataJson.rsearch.dateCompleteTimeArr;
				dataJson.rsearch.beginCompleteTime = htimeFormat(beginCompleteTime);
				dataJson.rsearch.endCompleteTime = htimeFormat(endCompleteTime);
			}
			if (dataJson.rsearch.dateSignTimeArr) {
				const [beginSignTime, endSignTime] = dataJson.rsearch.dateSignTimeArr;
				dataJson.rsearch.beginSignTime = htimeFormat(beginSignTime);
				dataJson.rsearch.endSignTime = htimeFormat(endSignTime);
			}
			const priceFields2 = [
				'beginPrice', 'endPrice',
				'beginRealPrice', 'endRealPrice',
				'beginCost', 'endCost'
			];
			
			priceFields2.forEach(field => {
				if (dataJson.rsearch[field] === 0) {
					dataJson.rsearch[field] = null;
				}
			});
			const res = await hpostRequest('hcz/compreport/repairlist', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default getCompreportDevService;
