<template>
	<div class="app-container">
		<KeepSearchForm @searchForm="searchFormHandler"></KeepSearchForm>
		<!-- <Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'devBase'"
		></Button> -->
		<div style="margin-top: 15px; margin-bottom: 15px">
			<el-button type="primary" size="mini" @click="exportExcel">
				导出
			</el-button>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="编号" prop="listNum"></el-table-column>
			<el-table-column label="服务公司" prop="orgName"></el-table-column>
			<el-table-column
				label="负责人"
				prop="dutyPeopleName"
			></el-table-column>
			<el-table-column label="设备数量" prop="devNumber">
				<template slot-scope="scope">
					{{ scope.row.devNumber }}
					({{ scope.row.allprice }}￥)
				</template>
			</el-table-column>
			<el-table-column
				label="科室/分类"
				prop="hospitalOfficeName"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					<template v-if="scope.row.inspectionType == 0">
						{{ scope.row.hospitalOfficeName }}
					</template>
					<template v-else-if="scope.row.inspectionType == 1">
						{{ scope.row.devTypesName }}
					</template>
				</template>
			</el-table-column>
			<el-table-column label="实施日期" prop="executionTime">
				<template slot-scope="scope">
					{{ scope.row.executionTime | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="状态" prop="state">
				<template slot-scope="scope">
					<el-link
						v-if="Number(scope.row.state) === 0"
						type="warning"
						:underline="false"
					>
						未开始
					</el-link>
					<el-link
						v-else-if="Number(scope.row.state) === 1"
						type="primary"
						:underline="false"
					>
						进行中
					</el-link>
					<el-link
						v-else-if="Number(scope.row.state) === 2"
						type="success"
						:underline="false"
					>
						已完成
					</el-link>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="text"
						@click="showDetail(scope.row)"
					>
						详情
					</el-button>
					<!-- <el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'devBase', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button> -->
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<!-- <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DevBaseAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></DevBaseAddForm>
		</Dialog> -->
		<Dialog title="保养单信息" :visible.sync="isShowAEDialog" width="50%">
			<InspectionImplementAdd
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:editId_p="editId"
				:status_p="status"
				:isHideAction_p="true"
			></InspectionImplementAdd>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	keepListService,
	devBaseDeleteService,
	devBaseDetailService
} from '@s/hospital/keep/KeepService';
import Dialog from '@c/ui/Dialog';
/* import Button from '@c/ui/Button'; */
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
/* import DevBaseAddForm from '@f/hospital/equipment/DevBaseAdd.form'; */
import KeepSearchForm from '@f/hospital/keep/KeepSearch.form';
import InspectionImplementAdd from '@f/company/keep/InspectionImplementAdd.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage, htimeFormat } from '@u/htools.web';

export default {
	name: 'KeepHospital',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		/* Button, */
		/* DevBaseAddForm, */
		KeepSearchForm,
		InspectionImplementAdd
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			tableDownData: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.inspectionList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.inspectionList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.inspectionList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.inspectionList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.inspectionList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async inspectionList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				hospitalId: hgetStorage('organization'),
				...this.searchForm
			};
			const res = await keepListService(dataJson);
			this.listMixin(res);
		},
		async getDownList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 9999,
				...this.searchForm
			};
			const res = await keepListService(dataJson);
			this.tableDownData = res?.records || [];
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				devBaseId: editId
			};
			const res = await devBaseDetailService(dataJson);
			this.selectData = [res];
			// this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				devBaseId: ids
			};
			await devBaseDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				devInfoId: row.id
			};
			const res = await devBaseDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await devBaseDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.inspectionList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		},
		showDetail(row) {
			this.isShowAEDialog = true;
			this.editId = row.id;
			this.status = row.state;
		},
		async exportExcel() {
			await this.getDownList();

			if (this.tableDownData.length == 0 || !this.tableDownData) {
				this.$message.warning('没有需要导出的数据');
				return false;
			}

			this.tableDownData.forEach(item => {
				if (item.state == 0) {
					item.stateName = '未开始';
				} else if (item.state == 1) {
					item.stateName = '进行中';
				} else if (item.state == 2) {
					item.stateName = '已完成';
				}
				item.executionTime = htimeFormat(item.executionTime);
				item.keshifenlei =
					item.inspectionType == 0
						? item.hospitalOfficeName
						: item.devTypesName;
			});

			const tHeader = [
				'编号',
				'服务公司',
				'负责人',
				'设备数量',
				'科室/分类',
				'实施日期',
				'状态'
			];
			const filterVal = [
				'listNum',
				'orgName',
				'dutyPeopleName',
				'devNumber',
				'keshifenlei',
				'executionTime',
				'stateName'
			];
			const data = this.formatJson(filterVal, this.tableDownData);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `保养工单${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		}
	}
};
</script>
<style lang="less" scoped></style>
