<template>
	<div class="app-container">
		<DeviceSearchForm @searchForm="searchFormHandler"></DeviceSearchForm>
		<div style="height: 15px"></div>
		<el-button
			type="default"
			@click="showPrintDialog"
			:disabled="selectData.length < 1"
		>
			批量打印设备二维码
		</el-button>
		<!-- <el-button
			type="default"
			@click="showPrintDialogStyle1"
			:disabled="selectData.length < 1"
		>
			批量打印设备二维码(新)
		</el-button> -->

		<el-button type="primary" @click="download" size="mini">导出</el-button>
		<div style="height: 15px"></div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="ID" prop="id" width="50">
				<template slot-scope="scope">
					{{ scope.row.devInfoId }}
				</template>
			</el-table-column>
			<el-table-column label="医院名称" prop="affiliatedHospitalName">
				<template slot-scope="scope">
					<p>{{ scope.row.affiliatedHospitalName }}</p>
					<p>{{ scope.row.devInfoDepartmentName }}</p>
				</template>
			</el-table-column>
			<el-table-column label="设备名称" prop="devInfoName">
				<template slot-scope="scope">
					{{ scope.row.devInfoName }}
				</template>
			</el-table-column>
			<el-table-column label="设备类型" prop="devInfoTypeName">
				<template slot-scope="scope">
					{{ scope.row.devInfoTypeName }}
				</template>
			</el-table-column>
			<el-table-column label="厂家" prop="devInfoFactoryName">
				<template slot-scope="scope">
					<p>{{ scope.row.devInfoFactoryName }}</p>
					<p>{{ scope.row.devInfoBrand }}</p>
					<p>{{ scope.row.devInfoNns1 }}</p>
				</template>
			</el-table-column>

			<el-table-column label="设备金额" prop="devInfoNns3">
				<template slot-scope="scope">
					<p>{{ scope.row.devInfoNns3 }}</p>
					<p>{{ scope.row.devInfoEnableTime | formatDate }}</p>
				</template>
			</el-table-column>

			<el-table-column label="查看" width="180">
				<template slot-scope="scope">
					<el-button
						id="btn-view-row"
						type="success"
						@click="viewSingleHandler(scope.row)"
						size="mini"
					>
						查看
					</el-button>

					<!-- id="btn-editSign-row"
						v-authorize="{
							name: 'editSign',
							type: 'hospitalRepair',
							id: 'btn-editSign-row'
						}" -->
					<!-- v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }" -->
					<!-- <el-button
						type="primary"
						@click="addRepairHandler(scope.row)"
						size="mini"
						id="btn-Repair-row"
						v-authorize="{ name: 'Repair', type: 'companyDevice', id: 'btn-Repair-row' }"
					>
						报修
					</el-button> -->
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>

		<!-- 批量打印设备二维码 -->
		<Dialog
			title="打印二维码"
			:visible.sync="isShowPrintDialog"
			width="320px"
		>
			<PrintDev
				v-if="isShowPrintDialog"
				:isShowPrintDialog_p.sync="isShowPrintDialog"
				:selectData_p="selectData"
			></PrintDev>
		</Dialog>
		<Dialog
			title="打印二维码"
			:visible.sync="isShowPrintDialogStyle1"
			width="400px"
		>
			<PrintDevStyle1
				v-if="isShowPrintDialogStyle1"
				:isShowPrintDialog_p.sync="isShowPrintDialogStyle1"
				:selectData_p="selectData"
			></PrintDevStyle1>
		</Dialog>
		
		<Dialog title="添加" :visible.sync="isShowAERepairDialog" width="72%">
			<RepairAdd
				:repairDevId="repairDevId"
				:repairDevTypeId="repairDevTypeId"
				:repairDepartmentId="repairDepartmentId"
				v-if="isShowAERepairDialog"
				:isShowAERepairDialog_p.sync="isShowAERepairDialog"
			></RepairAdd>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { deviceListService } from '@s/company/device/DeviceService';
import Dialog from '@c/ui/Dialog';
import DeviceSearchForm from '@f/company/device/DeviceSearch.form';
import PrintDev from '@f/hospital/devBase/PrintDev';
import PrintDevStyle1 from '@f/hospital/devBase/PrintDevStyle1';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import RepairAdd from '@f/repair/RepairAdd.form';
import ListMixin from '@m/List.mixin';
// import PrintDevStyle1 from '@/factory/hospital/devBase/PrintDevStyle1.vue';

export default {
	name: 'CompanyDevice',
	mixins: [ListMixin],
	components: {
		Table,
		Pagination,
		DeviceSearchForm,
		Dialog,
		PrintDev,
		PrintDevStyle1,
		RepairAdd
	},
	data() {
		return {
			isShowPrintDialog: false,
			isShowPrintDialogStyle1: false,
			// 表格
			tableColumn: [],
			isShowAERepairDialog: false,
			repairDevId: '',
			repairDevTypeId: '',
			repairDepartmentId: '',
			tableDownData: []
		};
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.deviceList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.deviceList();
		}
	},
	mounted() {
		// this.deviceList();
	},
	methods: {
		addRepairHandler(item) {
			this.repairDevId = item.devInfoId;
			this.repairDevTypeId = item.devInfoType;
			this.repairDepartmentId = item.devInfoDepartmentId;
			this.isShowAERepairDialog = true;
		},
		async viewSingleHandler(row) {
			this.$router.push({
				name: 'DevBaseDetai',
				query: { type: 'company', devId: row.devInfoId }
			});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.deviceList();
		},
		async deviceList() {
			console.log('searchForm');
			console.log(this.searchForm);
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await deviceListService(dataJson);
			this.listMixin(res);
		},
		async getDownList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 99999,
				...this.searchForm
			};
			const res = await deviceListService(dataJson);
			res?.records?.forEach((item, index) => {
				item.indexNo = index + 1;

				if (item.useType == 1) {
					item.useTypeName = '在账在用';
				} else if (item.useType == 2) {
					item.useTypeName = '不在账在用';
				} else if (item.useType == 3) {
					item.useTypeName = '在账不在用';
				}
			});

			this.tableDownData = res?.records || [];
		},

		async download() {
			await this.getDownList();

			const tHeader = [
				'ID',
				'医院',
				'科室',
				'设备名称',
				'类型',
				'厂家',
				'品牌',
				'型号',
				'序列号',
				'金额',
				'购入日期',
				'在用状态'
			];

			const filterVal = [
				'devInfoId',
				'affiliatedHospitalName',
				'devInfoDepartmentName',
				'devInfoName',
				'devInfoTypeName',
				'devInfoFactoryName',
				'devInfoBrand',
				'devInfoNns1',
				'devInfoSerialNo',
				'devInfoNns3',
				'devInfoEnableTime',
				'useTypeName'
			];
			const data = this.formatJson(filterVal, this.tableDownData);
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;

			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备列表${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.deviceList();
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		showPrintDialog() {
			this.isShowPrintDialog = true;
		},
		showPrintDialogStyle1() {
			this.isShowPrintDialogStyle1 = true;
		}
	}
};
</script>
<style lang="less" scoped></style>
