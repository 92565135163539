<template>
	<div>
		<div class="search_box">
			<el-form ref="refFormSearch" :model="formSearch" label-width="80px" :inline="false" style="display: flex">
				<el-form-item label="医院分组" v-if="isShowHosid">
					<el-select v-model="formSearch.hosids" placeholder="请选择" multiple collapse-tags clearable filterable>
						<el-option-group v-for="group in hospitalDataGrop" :key="group.label" :label="group.label">
							<el-option v-for="item in group.options" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-option-group>
					</el-select>
				</el-form-item>

				<el-form-item label="设备类型">
					<treeselect
						v-model="formSearch.typeids"
						:multiple="true"
						:options="deviceTypeData"
						style="width: 300px"
						:disable-branch-nodes="true"
						:normalizer="normalizer"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
						:clearable="true"
						:limit="1"
						:limitText="count => `+ ${count}`"
					/>
				</el-form-item>

				<el-form-item label="价格范围">
					<el-input-number :precision="2" :controls="false" controls-position="right" v-model="formSearch.beginBuyPrice"></el-input-number>
				</el-form-item>
				<el-form-item label-width="10px">
					<el-input-number :precision="2" :controls="false" controls-position="right" v-model="formSearch.endBuyPrice"></el-input-number>
				</el-form-item>

				<el-form-item label="关键字">
					<el-input v-model="formSearch.keyword"></el-input>
				</el-form-item>
				<el-form-item label="日期">
					<el-date-picker
						v-model="formSearch.date"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
			</el-form>

			<el-form ref="refFormCount" :model="formCount" label-width="80px" :inline="true">
				<el-form-item label="分组">
					<el-select v-model="formCount.groupBy" placeholder="请选择" multiple :multiple-limit="2" filterable @change="getXData">
						<el-option v-for="item in typeListgroupby" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="X轴">
					<el-select v-model="formCount.xTemp" placeholder="请选择" filterable>
						<el-option v-for="item in typeListX" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="Y轴">
					<el-select v-model="formCount.yTemp" placeholder="请选择" filterable :disabled="formCount.groupBy.length != 2">
						<el-option v-for="item in typeListY" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" v-if="formCount.groupBy.includes('5')">
					<el-checkbox v-model="formCount.includeUnnameFac">包含未知产家</el-checkbox>
				</el-form-item>

				<el-button type="success" class="search_button" @click="searchSubmit" size="mini">搜索</el-button>
			</el-form>
			<!-- <el-button
				type="success"
				class="search_button"
				@click="searchSubmit"
				size="mini"
			>
				搜索
			</el-button> -->
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="24">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>设备统计</span>
						<el-button size="mini" round @click="downloadExcelDevCntAndPrice()">导出</el-button>
						<!-- <el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(1)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button> -->
					</div>
					<div class="echart_item1">
						<div id="echarts1" class="echart_box1"></div>
					</div>
					<!-- <div class="date_tag">
						<el-button
							:class="{ activeDate: dateType == 0 }"
							size="mini"
							round
							@click="dateSwitch(0)"
						>
							年
						</el-button>
						<el-button
							:class="{ activeDate: dateType == 1 }"
							size="mini"
							round
							@click="dateSwitch(1)"
						>
							季
						</el-button>
						<el-button
							:class="{ activeDate: dateType == 2 }"
							size="mini"
							round
							@click="dateSwitch(2)"
						>
							月
						</el-button>
					</div> -->
				</el-card>
			</el-col>
		</el-row>
		<Dialog :title="dialogTitle" :visible.sync="isShowBigEcharts" width="90%" @before-close="beforeCloseHandler">
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>

		<el-dialog
			:title="'设备统计详情(' + filteredData.length + ')'"
			:visible.sync="isShowDialogVisibleDevDetail"
			@close="loadingDetail = false"
			:close-on-click-modal="false"
			:custom-class="'tableDialogDetail'"
			width="70%"
			:append-to-body="true"
			v-loading="loadingDetail"
		>
			<el-form :inline="true" ref="refFormDetail" :model="formDetail" label-width="80px">
				<el-form-item>
					<el-input v-model="formDetail.keyword" placeholder="请输入关键字" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="mini" @click="downloadExcelDetail">导出</el-button>
				</el-form-item>
			</el-form>
			<el-table
				ref="devDetailTableDataRef"
				:data="filteredData"
				tooltip-effect="dark"
				style="width: 100%"
				class="tableCursor"
				height="550"
				:empty-text="' '"
			>
				<el-table-column type="index" width="50" label="序号"></el-table-column>
				<el-table-column label="Id" prop="dev_info_id" width="60">
					<template slot-scope="scope">
						<p style="cursor: pointer; color: #409eff" @click="toDevDetail(scope.row)">{{ scope.row.dev_info_id }}</p>
					</template>
				</el-table-column>
				<el-table-column label="医院" prop="hosname"></el-table-column>
				<el-table-column label="科室" prop="department"></el-table-column>
				<el-table-column label="设备名称" prop="devname"></el-table-column>
				<el-table-column label="类型" prop="ptypename" min-width="150">
					<template slot-scope="scope">
						<p>{{ scope.row.ptypename }}</p>
						<p>{{ scope.row.devtypename }}</p>
					</template>
				</el-table-column>
				<el-table-column label="厂家" prop="facshort"></el-table-column>
				<el-table-column label="品牌" prop="dev_info_brand"></el-table-column>
				<el-table-column label="型号" prop="xh"></el-table-column>
				<el-table-column label="购入日期" prop="dev_info_enable_time">
					<template slot-scope="scope">
						{{ scope.row.devInfoDepartmentName | parseTime('{y}-{m}-{d}') }}
					</template>
				</el-table-column>
				<el-table-column label="价格范围" prop="pricerange"></el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/company/contract/ContractService';
// 获取保养设备列表
import { devBaseTypeListService } from '@s/hospital/devBase/DevBaseService';
import { allHospitalRepairService } from '@s/hospital/StatisticsService';
import { getCompreportDevService, getCompreportDevlistService } from '@s/hospital/CompreportDevService';
import Dialog from '@c/ui/Dialog';
// import SelectTree from '@c/ui/SelectTree';
import Treeselect from '@riophae/vue-treeselect';
import { htimeFormat, hgetStorage, hdateInMonth } from '@u/htools.web';
import * as math from 'mathjs';

export default {
	components: {
		Dialog,
		Treeselect
	},

	data() {
		return {
			// 柱形图表 点击详情
			isShowDialogVisibleDevDetail: false,
			devDetailTableData: [],
			formDetail: {
				keyword: ''
			},
			loadingDetail: true,
			date: '',
			dateType: 2, // 月
			dateEndType: 2, // 月
			dateEndTypeSigntime: 2, // 月
			dialogTitle: '',
			tableDownDataDevCntAndPrice: [],
			formSearch: {
				beginBuyDate: null,
				beginBuyPrice: undefined,
				endBuyDate: null,
				endBuyPrice: undefined,
				hosids: [],
				keyword: null,
				typeids: [],
				date: []
			},
			formCount: {
				chartType: 0,
				doubleY: true,
				groupBy: [],
				x: 1,
				xTemp: null,
				yTemp: '0',
				y1: '*',
				y1AggType: 0,
				y2: 'price',
				y2AggType: 1,
				includeUnnameFac: false
			},
			// 医院下拉搜索
			isShowHosid: false,
			hospitalData: [],
			hospitalDataGrop: [
				{
					serviceState: 1,
					label: '托管服务',
					options: []
				},
				{
					serviceState: 2,
					label: '临时服务',
					options: []
				},
				{
					serviceState: 0,
					label: '不服务',
					options: []
				}
			],
			// 设备类型
			deviceTypeData: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			},
			// 分组下拉
			typeListgroupby: [
				{
					value: '0',
					label: '医院',
					e: 'hosname'
				},
				{
					value: '1',
					label: '父类',
					e: 'ptypename'
				},
				{
					value: '2',
					label: '子类',
					e: 'devtypename'
				},
				{
					value: '3',
					label: '价格范围',
					e: 'pricerange'
				},
				{
					value: '4',
					label: '年限',
					e: 'years'
				},
				{
					value: '5',
					label: '厂家',
					e: 'facshort'
				}
			],
			// 分组X下拉
			typeListX: [],
			typeListY: [
				{
					value: '0',
					label: '数量',
					e: 'y1'
				},
				{
					value: '1',
					label: '价格',
					e: 'y2'
				}
			],
			myChart1: null,
			options1: {
				// title: {
				// 	text: '设备统计'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter: arg => {
					// 	console.log(arg[0]);
					// 	// return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					// }
				},
				dataZoom: [
					{
						type: 'slider',
						show: true,
						start: 0,
						end: 30,
						handleSize: 8
					}
				],
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					// bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			isShowBigEcharts: false
		};
	},

	computed: {
		filteredData() {
			return this.devDetailTableData.filter(item => {
				return !this.formDetail.keyword || item.hosname.includes(this.formDetail.keyword) || item.devname.includes(this.formDetail.keyword);
			});
		}
	},
	watch: {
		tableDownDataDevCntAndPrice: {
			handler(newVal, oldVal) {
				this.myChart1.hideLoading();
			},
			deep: true
		}
	},
	async created() {
		this.typeListX = [
			{
				value: '0',
				label: '医院',
				e: 'hosname'
			},
			{
				value: '1',
				label: '父类',
				e: 'ptypename'
			}
		];
		this.formCount.groupBy = ['0', '1'];
		this.formCount.xTemp = '0';
		// 搜索条件 医院下拉
		await this.contractList();
		this.deviceTypeList();
		this.isShowHosid = true;
	},
	async mounted() {
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.date = [`${curYear - 10}-01-01 00:00:00`, `${curYear}-${curMonth}-${endDate} 23:59:59`];
		// this.formSearch.date = [`${curYear - 10}-01-01 00:00:00`, `${curYear}-${curMonth}-${endDate} 23:59:59`];

		this.allHospitalRepairList(this.dateType);

		// 公司管理设备、报修统计 ----->>>>>    公司管理设备统计
		this.getCompreportDevService();
	},

	methods: {
		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.deviceType;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKeyTypeList(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 设备类型
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
		},
		// 医院下拉
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await getcompanysignListService(dataJson);
			this.hospitalData = [...res.records];
			this.hospitalDataGrop.forEach(item1 => {
				res?.records?.forEach(item2 => {
					if (item1.serviceState == item2.serviceState) {
						item1.options.push(item2);
					}
				});
			});
		},
		getXData(val) {
			const typeListgroupby = [...this.typeListgroupby];
			const typeListX = [];
			val.forEach(item1 => {
				typeListgroupby.forEach(item2 => {
					if (item1 == item2.value) {
						typeListX.push(item2);
					}
				});
			});

			this.typeListX = typeListX;
			// this.formCount.xTemp = typeListX[0];
			[this.formCount.xTemp] = typeListX[0].value;
		},
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart1 = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '设备统计';
					this.myChart1.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '报修次数(次)';
					delete this.options2.series[0].itemStyle.normal.label.width;
					this.myChart1.setOption(this.options2);
				}
				if (index === 3) {
					this.dialogTitle = '报修费用(元)';
					delete this.options3.series[0].itemStyle.normal.label.width;
					this.myChart1.setOption(this.options3);
				}
				if (index === 4) {
					this.dialogTitle = '类型报修次数(次)';
					delete this.options4.series[0].itemStyle.normal.label.width;
					this.myChart1.setOption(this.options4);
				}
				if (index === 5) {
					this.dialogTitle = '类型报修费用(元)';
					delete this.options5.series[0].itemStyle.normal.label.width;
					this.myChart1.setOption(this.options5);
				}
				if (index === 6) {
					this.dialogTitle = '报修次数及占比';
					this.myChart1.setOption(this.options6);
				}
				if (index === 7) {
					this.dialogTitle = '费用及占比';
					this.myChart1.setOption(this.options7);
				}
				if (index === 8) {
					this.dialogTitle = '维修完成数量和费用';
					this.myChart1.setOption(this.options8);
				}
				if (index === 9) {
					this.dialogTitle = '维修完成数量和费用（签单时间）';
					this.myChart1.setOption(this.options9);
				}
				if (index === 10) {
					this.dialogTitle = '科室维修次数与金额';
					this.myChart1.setOption(this.options10);
				}
				if (index === 11) {
					this.dialogTitle = '巡检月度';
					this.myChart1.setOption(this.options11);
				}
				if (index === 12) {
					this.dialogTitle = '保养月度';
					this.myChart1.setOption(this.options12);
				}
				if (index === 13) {
					this.dialogTitle = '当月巡检完成率';
					this.myChart1.setOption(this.options13);
				}
				if (index === 14) {
					this.dialogTitle = '当月保养完成率';
					this.myChart1.setOption(this.options14);
				}
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
			this.allHospitalRepairList(this.dateType);
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
		},
		dateEndSwitchSigntime(dateType) {
			this.dateEndTypeSigntime = dateType;
		},
		async searchSubmit() {
			// this.allHospitalRepairList(this.dateType);

			this.getCompreportDevService();
			// this.test();
		},

		test() {
			const hospitals = ['医院A', '医院B', '医院C'];
			const devices = ['设备1', '设备2', '设备3', '设备4'];
			const res = [
				{ hosname: '医院A', devtypename: '设备1', y1: 10 },
				{ hosname: '医院A', devtypename: '设备2', y1: 20 },
				{ hosname: '医院B', devtypename: '设备1', y1: 15 },
				{ hosname: '医院C', devtypename: '设备3', y1: 25 },
				{ hosname: '医院C', devtypename: '设备4', y1: 15 }
			];
			const yAxisData = {};
			const yAxisData1 = {};

			hospitals.forEach(hospital => {
				yAxisData[hospital] = devices.map(device => {
					const found = res.find(item => item.hosname === hospital && item.devtypename === device);
					return found ? found.y1 : 0;
				});
			});

			devices.forEach(device => {
				yAxisData1[device] = hospitals.map(hospital => {
					const found = res.find(item => item.devtypename === device && item.hosname === hospital);
					return found ? found.y1 : 0;
				});
			});
		},

		async allHospitalRepairList(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = (await allHospitalRepairService(dataJson)) || [];

			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairCntAndPriceByMonth = res;
			this.echartsInit1_1(res);
		},
		async getCompreportDevService(dateType) {
			const chartDom = document.getElementById('echarts1');
			if (this.myChart1 != null && this.myChart1.dispose) {
				this.myChart1.dispose();
			}
			this.myChart1 = this.$echarts.init(chartDom);
			this.myChart1.clear();

			// 显示loading效果
			this.myChart1.showLoading({
				text: '数据加载中...',
				color: '#c23531', // 可以自定义loading文字的颜色
				textColor: '#000', // 可以自定义loading文字的字色
				maskColor: 'rgba(255, 255, 255, 0.8)', // 可以自定义遮罩层的颜色
				zlevel: 0 // 控制loading效果的层级
			});
			const dataJson = {
				cfg: {
					// chartType: 0,
					// doubleY: true,
					// groupBy: [4],
					// x: 1,
					// y1: '*',
					// y1AggType: 0,
					// y2: 'price',
					// y2AggType: 1,
					...this.formCount
				},
				search: {
					// beginBuyDate: this.formSearch.date[0],
					// beginBuyPrice: 0,
					// endBuyDate: this.formSearch.date[1],
					// endBuyPrice: 999999,
					// hosids: [],
					// keyword: '迈瑞',
					// orgid: 1118,
					// typeids: [],
					...this.formSearch
				}
			};
			const res = await getCompreportDevService(dataJson);
			this.tableDownDataDevCntAndPrice = res || [];
			// this.$echarts.dispose(this.myChart1);

			if (dataJson.cfg.groupBy.length == 1) {
				this.echartsInit1_1(res);
			}
			if (dataJson.cfg.groupBy.length == 2) {
				this.echartsInit1_2(res);
			}

			// this.myChart1.on('finished', () => {
			// 	console.log(113, 'finished');
			// 	this.myChart1.hideLoading();
			// 	// ...
			// });
		},
		echartsInit1_1(res) {
			this.options1.series = [
				{
					name: '数量',
					type: 'bar',
					data: [],
					itemStyle: {
						normal: {
							color: '#4BC2DF'
						}
					}
				},
				{
					name: '价格',
					type: 'line',
					yAxisIndex: 1,
					data: [],
					itemStyle: {
						normal: {
							color: '#C51CDD'
						}
					}
				}
			];
			this.options1.tooltip = {
				trigger: 'axis',
				formatter: arg => {
					let str0 = `<div class="ownTooltip">${arg[0].axisValueLabel}<br/>`;

					arg.forEach(item => {
						// if (item.data != 0) {
						if (item.seriesName == '价格') {
							item.data2 = this.formatNumber(item.data);
						} else {
							item.data2 = this.addCommas(item.data);
						}
						str0 += `<p class="itemWrap"><span class="legendRound" style="background:${item.color}"></span>
							<span class="key">${item.seriesName}</span> <span class="value">${item.data2}</span></p>`;
						// }
					});
					str0 += '</div>';
					return str0;
				}
			};
			const xAxisArr = [];
			const lineArr = [];
			const barArr = [];

			// 医院0  hosname
			// 父类1  ptypename
			// 子类2  devtypename
			// 价格范围3  pricerange
			// 年限4 years
			// 厂家 facshort
			res.forEach(item => {
				xAxisArr.push(item.hosname || item.ptypename || item.devtypename || item.pricerange || item.years || item.facshort);
				barArr.push(item.y1);
				lineArr.push(item.y2);
			});
			this.options1.xAxis[0].data = xAxisArr;
			this.options1.series[0].data = barArr;
			this.options1.series[1].data = lineArr;
			this.myChart1.setOption(this.options1);
		},
		echartsInit1_2(res) {
			let legendArr = [];
			let xAxisArr = [];
			const lineArr = [];
			const barArr = [];

			// x轴的字段
			const eY = this.typeListY.filter(item => item.value == this.formCount.yTemp)[0].e;
			// x轴的字段x1
			const eX = this.typeListX.filter(item => item.value == this.formCount.xTemp)[0].e;
			// 图例的字段
			const eL = this.typeListX.filter(item => item.value != this.formCount.xTemp)[0].e;

			// res.forEach(item1 => {
			// 	xAxisArr.push(item1[eX]);
			// });
			xAxisArr = Array.from(new Set(res.map(item => item[eX]))).sort((a, b) => {
				return parseInt(a, 10) - parseInt(b, 10);
			});
			legendArr = Array.from(new Set(res.map(item => item[eL]))).sort((a, b) => {
				return parseInt(a, 10) - parseInt(b, 10);
			});

			if (legendArr.includes('10w< x <=50w')) {
				legendArr = ['x<=1w', '1w< x <=10w', '10w< x <=50w', '50w< x <=100w'];
			}

			// const yAxisData = {};
			// legendArr.forEach(item1 => {
			// 	yAxisData[item1] = xAxisArr.map(item2 => {
			// 		const found = res.find(
			// 			item3 =>
			// 				item3.ptypename === item1 &&
			// 				item3.hosname === item2
			// 		);
			// 		return found ? found.y1 : 0;
			// 	});
			// });
			// const yAxisData = {};
			// legendArr.forEach(item1 => {
			// 	yAxisData[item1] = xAxisArr.map((item2, index2, arr2) => {
			// 		const found = res.find(item3 => item3[eL] === item1 && item3[eX] === item2);
			// 		// if (item1 == '襄垣县人民医院') {
			// 		// 	console.log('襄垣县人民医院');
			// 		// 	if (found?.facshort == '迈瑞') {
			// 		// 		console.log(found);
			// 		// 	}
			// 		// }
			// 		return found ? found[eY] : 0;
			// 	});
			// });
			const yAxisData = {};
			legendArr.forEach(item1 => {
				yAxisData[item1] = xAxisArr.map((item2, index2, arr2) => {
					const filteredItems = res.filter(item3 => item3[eL] === item1 && item3[eX] === item2);
					return filteredItems.reduce((sum, item3) => sum + (item3[eY] || 0), 0);
				});
			});

			this.options1.series = [];
			legendArr.forEach(item1 => {
				this.options1.series.push({
					name: item1,
					info: { some: 123 },
					type: 'bar',
					stack: 'total',
					smooth: true,
					data: yAxisData[item1],
					itemStyle: {
						normal: {
							// color: '#4BC2DF'
						}
					}
				});
			});

			this.options1.tooltip = {
				trigger: 'axis',
				formatter: arg => {
					let count = arg.reduce((prev, cur) => {
						return math.add(math.bignumber(prev), math.bignumber(cur.value));
					}, 0);
					if (this.formCount.yTemp != 0) {
						count = this.formatNumber(count);
					} else {
						count = this.addCommas(count);
					}
					let str0 = '';
					let itemLen = 0;
					arg.forEach(item => {
						if (item.data != 0) {
							itemLen++;

							// item.data2 = item.data;
							if (this.formCount.yTemp != 0) {
								item.data2 = this.formatNumber(item.data);
							} else {
								item.data2 = this.addCommas(item.data);
							}
							// if (this.formCount.yTemp != 0) {
							// 	str0 += `<span class="itemWrap"><span class="key">${item.marker}<span class="seriesName">${item.seriesName || '-'
							// 		}</span></span> <span class="value">${item.data.toFixed(2)}</span></span>`;
							// } else {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}<span class="seriesName">${
								item.seriesName || '-'
							}</span></span> <span class="value">${item.data2}</span></span>`;
							// }
						}
					});
					if (itemLen > 0 && itemLen <= 20) {
						return `<div class="ownTooltip">${arg[0].axisValueLabel}<b style="color:red">(合计:${count})</b><br/>${str0}</div>`;
					} else if (itemLen > 20 && itemLen <= 40) {
						return `<div class="ownTooltip">${arg[0].axisValueLabel}<b style="color:red">(合计:${count})</b><br/><div class="itemTwoLine">${str0}</div></div>`;
					} else if (itemLen > 40 && itemLen <= 80) {
						return `<div class="ownTooltip">${arg[0].axisValueLabel}<b style="color:red">(合计:${count})</b><br/><div class="itemThreeLine">${str0}</div></div>`;
					} else {
						return `<div class="ownTooltip">${arg[0].axisValueLabel}<b style="color:red">(合计:${count})</b><br/><div class="itemFourLine">${str0}</div></div>`;
					}
				}
			};

			this.options1.toolbox = {
				show: true,
				orient: 'vertical',
				left: 'right',
				top: 'center',
				feature: {
					magicType: {
						show: true,
						type: ['line', 'bar', 'stack'],
						title: {
							line: '切换折线',
							bar: '切换柱形',
							stack: '切换堆叠'
						}
					},
					saveAsImage: { show: true, title: '保存图片' }
				}
			};

			if (legendArr.length > 30) {
				this.options1.legend = {
					type: 'scroll'
				};
			} else {
				this.options1.legend = {
					type: 'plain'
				};
			}

			// res.forEach(item1 => {
			// 	legendArr.forEach((item2, index2, arr2) => {
			// 		if (item1[eL] == item2) {
			// 			// console.log('index2--------------------');
			// 			// console.log(item1.y1);
			// 			// console.log(item2);
			// 			// console.log(index2);
			// 			// console.log(this.options1.series[index2].name);

			// 			this.options1.series[index2].data[index2] = item1.y1;
			// 		}
			// 	});
			// });

			this.options1.xAxis[0].data = xAxisArr;
			this.myChart1.setOption(this.options1);
			this.myChart1.on('finished', () => {
				console.log(113, 'finished');
				this.myChart1.hideLoading();
				// ...
			});
			this.myChart1.on('click', params => {
				// this.myChart1.on('click', 'series', params => {
				// this.devDetailTableData = [];
				this.loadingDetail = true;
				this.setClickBarHandler({ ...params, arr: res });
			});
		},
		setClickBarHandler(data) {
			const dataJson = {
				cfg: {
					...this.formCount
				},
				search: {
					...this.formSearch
				}
			};
			const { name, seriesName, arr } = data;
			let currentData = {};
			// console.log('click 事件------------------');
			// console.log(name);
			// console.log(seriesName);
			// console.log(arr);

			// x轴的字段
			const eY = this.typeListY.filter(item => item.value == this.formCount.yTemp)[0].e;
			// x轴的字段x1
			const eX = this.typeListX.filter(item => item.value == this.formCount.xTemp)[0].e;
			// 图例的字段
			const eL = this.typeListX.filter(item => item.value != this.formCount.xTemp)[0].e;
			// console.log(eY);
			// console.log(eX);
			// console.log(eL);

			for (let i = 0; i < arr.length; i++) {
				if (arr[i][eX] == name && arr[i][eL] == seriesName) {
					currentData = arr[i];
				}
			}
			// 优化前
			if (eX == 'hosname') {
				dataJson.search.hosids = [currentData.hosid];
			} else if (eX == 'ptypename') {
				dataJson.search.typepids = [currentData.pid];
			} else if (eX == 'devtypename') {
				dataJson.search.typeids = [currentData.typeid];
			} else if (eX == 'pricerange') {
				dataJson.search.pricerange = currentData.pricerange;
			} else if (eX == 'years') {
				dataJson.search.years = currentData.years;
			} else if (eX == 'facshort') {
				// 所有包含迈瑞的数据 整理成一个数组
				const facids = this.getFacids({
					arr: arr,
					facshort: currentData.facshort
				});
				dataJson.search.facids = [...facids];
			}
			this.getTableData(dataJson);

			// 优化后
			// const searchFields = {
			// 	hosname: 'hosids',
			// 	ptypename: 'typepids',
			// 	devtypename: 'typeids',
			// 	pricerange: 'pricerange',
			// 	years: 'years'
			// };

			// if (searchFields[eX]) {
			// 	if (
			// 		eX === 'hosname' ||
			// 		eX === 'ptypename' ||
			// 		eX === 'devtypename'
			// 	) {
			// 		dataJson.search[searchFields[eX]] = [
			// 			currentData[
			// 				eX === 'hosname'
			// 					? 'hosid'
			// 					: eX === 'ptypename'
			// 					? 'pid'
			// 					: 'typeid'
			// 			]
			// 		];
			// 	} else {
			// 		dataJson.search[searchFields[eX]] = currentData[eX];
			// 	}
			// } else if (eX === 'facshort') {
			// 	const facids = this.getFacids({
			// 		arr: arr,
			// 		facshort: currentData.facshort
			// 	});
			// 	dataJson.search.facids = [...facids];
			// }
		},
		async getTableData(dataJson) {
			const res = await getCompreportDevlistService(dataJson);
			this.formDetail = {};
			this.devDetailTableData = res || [];
			this.loadingDetail = false;
			this.isShowDialogVisibleDevDetail = true;
		},
		getFacids(data) {
			const { arr, facshort } = data;
			const facids = [];
			arr.forEach(item => {
				if (item.facshort == facshort) {
					facids.push(item.facid);
				}
			});
			return facids;
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},

		downloadExcelDevCntAndPrice() {
			const tHeader = [];
			const filterVal = [];
			const obj = this.tableDownDataDevCntAndPrice[0] || {};
			const keys = Object.keys(obj);

			const fieldMap = {
				hosname: { header: '医院', value: 'hosname' },
				ptypename: { header: '父类', value: 'ptypename' },
				devtypename: { header: '子类', value: 'devtypename' },
				pricerange: { header: '价格范围', value: 'pricerange' },
				years: { header: '年限', value: 'years' },
				facshort: { header: '厂家', value: 'facshort' },
				y1: { header: '数量', value: 'y1' },
				y2: { header: '价格', value: 'y2' }
			};

			Object.keys(fieldMap).forEach(key => {
				if (keys.includes(key)) {
					tHeader.push(fieldMap[key].header);
					filterVal.push(fieldMap[key].value);
				}
			});

			// if (keys.includes('hosname')) {
			// 	tHeader.push('医院');
			// 	filterVal.push(hosname);
			// }
			// if (keys.includes('ptypename')) {
			// 	tHeader.push('父类');
			// 	filterVal.push(ptypename);
			// }
			// if (keys.includes('devtypename')) {
			// 	tHeader.push('子类');
			// 	filterVal.push(devtypename);
			// }
			// if (keys.includes('pricerange')) {
			// 	tHeader.push('价格范围');
			// 	filterVal.push(pricerange);
			// }
			// if (keys.includes('years')) {
			// 	tHeader.push('年限');
			// 	filterVal.push(years);
			// }
			// if (keys.includes('facshort')) {
			// 	tHeader.push('厂家');
			// 	filterVal.push(facshort);
			// }
			const data = this.formatJson(filterVal, this.tableDownDataDevCntAndPrice);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备统计${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelDetail() {
			this.filteredData.forEach(item => {
				item.dev_info_enable_time_new = htimeFormat(item.dev_info_enable_time);
			});
			const tHeader = ['Id', '医院', '科室', '设备名称', '大类', '小类', '厂家', '品牌', '型号', '购入日期', '价格范围'];
			const filterVal = [
				'dev_info_id',
				'hosname',
				'department',
				'devname',
				'ptypename',
				'devtypename',
				'facshort',
				'dev_info_brand',
				'xh',
				'dev_info_enable_time_new',
				'pricerange'
			];

			const data = this.formatJson(filterVal, this.filteredData);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备统计详情${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		toDevDetail(row) {
			this.isShowDialogVisibleDevDetail = false;
			this.$router.push({
				name: 'DevBaseDetai',
				query: { type: 'company', devId: row.dev_info_id }
			});
		},
		// 保留俩位的数字 千分位逗号切割
		formatNumber(num) {
			const formatter = new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
			return formatter.format(num);
		},
		// 不保留数字 千分位逗号切割
		addCommas(value) {
			const parts = value.toString().split('.');
			parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
			return parts.join('.');
		}
	}
};
</script>

<style scoped>
.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}

.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}

.search_box {
	margin-bottom: 20px;
}

.echart_item {
	width: 500px !important;
	height: 360px;
	margin: 0 auto;
}

.echart_box {
	width: 460px;
	height: 360px;
}

.echart_item1 {
	width: 90% !important;
	height: 760px;
	margin: 0 auto;
}

.echart_box1 {
	width: 100% !important;
	height: 760px;
}

.search_button {
	margin-left: 10px;
}

.el-col {
	margin-bottom: 20px;
}

.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}

.form {
	display: flex;
}

/deep/.ownTooltip {
	min-width: 2.4rem;
}

/deep/.ownTooltip .itemWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/deep/.ownTooltip .itemWrap .legendRound {
	width: 0.1rem;
	height: 0.1rem;
	border-radius: 50%;
	display: inline-block;
}

/deep/.ownTooltip .itemWrap .key {
	display: flex;
	align-items: center;
	margin-left: 0.02rem;
}

/deep/.ownTooltip .itemWrap .value {
	flex: 1;
	text-align: right;
}

/* 多行提示样式 俩列展示 */
/deep/.itemTwoLine {
	display: flex;
	flex-wrap: wrap;
	max-width: 6rem;
}

/deep/.itemTwoLine .itemWrap {
	width: 46%;
}

/deep/.itemTwoLine .itemWrap:not(:last-child) {
	margin-right: 4%;
}

/deep/.itemTwoLine .itemWrap .key .seriesName {
	width: 1.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* 多行提示样式 3列展示 */
/deep/.itemThreeLine {
	display: flex;
	flex-wrap: wrap;
	max-width: 6.8rem;
}

/deep/.itemThreeLine .itemWrap {
	width: 30%;
}

/deep/.itemThreeLine .itemWrap:not(:last-child) {
	margin-right: 3%;
}

/deep/.itemThreeLine .itemWrap .key .seriesName {
	width: 1.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* 多行提示样式 4列展示 */
/deep/.itemFourLine {
	display: flex;
	flex-wrap: wrap;
	max-width: 6.8rem;
}

/deep/.itemFourLine .itemWrap {
	width: 23%;
}

/deep/.itemFourLine .itemWrap:not(:last-child) {
	margin-right: 2%;
}

/deep/.itemFourLine .itemWrap .key .seriesName {
	width: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
