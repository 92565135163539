import Vue from 'vue';
import ElementUI from 'element-ui';
import * as echarts from 'echarts';
import * as moment from 'moment';
import Print from 'vue-print-nb';
import VueQriously from 'vue-qriously';
import videojs from 'video.js';
import { huploadConfigJson } from './util/htools.web';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'element-ui/lib/theme-chalk/index.css';
import 'video.js/dist/video-js.css';
import './directives/index';
import './filters/index';
import App from './App.vue';
import router from './router';
import store from './store';
// eslint-disable-next-line import/order
import Viewer from 'v-viewer';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css';
import './assets/font_4405230_gdl3hu9kmml/iconfont.css';
// 下拉选择带搜索  treeselect
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import './assets/css/fonts.css';

Vue.prototype.$moment = moment;
window.$moment = moment;

Vue.prototype.$video = videojs;
Vue.prototype.$echarts = echarts;

Viewer.setDefaults({
	Options: {
		inline: true,
		button: true,
		navbar: true,
		title: true,
		toolbar: true,
		tooltip: true,
		movable: true,
		zoomable: true,
		rotatable: true,
		scalable: true,
		transition: true,
		fullscreen: true,
		keyboard: true,
		url: 'data-source'
	}
});

Vue.config.productionTip = false;

Vue.use(Print);
Vue.use(VueQriously);

Vue.use(ElementUI, {
	size: 'small'
});
Vue.use(Viewer);

new Vue({
	router,
	store,
	render: h => {
		return h(App);
	},
	async created() {
		Vue.prototype.$envConfig = await huploadConfigJson();
	}
}).$mount('#app');
